import { FullscreenOverlayContainer, OverlayContainer, OverlayModule } from '@angular/cdk/overlay';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeIw from '@angular/common/locales/he';
import {
  APP_INITIALIZER,
  ErrorHandler,
  Inject,
  InjectionToken,
  Injector,
  NgModule,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { BaseComponent } from '@app/base.component';
import { configureI18Next, I18N_PROVIDERS } from '@app/_helpers/';
import { AwButtonModule } from '@aw/ads-components/aw-button';
import { AwCopyrightModule } from '@aw/ads-components/aw-copyright';
import { AwFloatingDialogModule } from '@aw/ads-components/aw-floating-dialog';
import { AwPanelModule } from '@aw/ads-components/aw-panel';
import { AwResponsiveLayoutModule } from '@aw/ads-components/aw-responsive-layout';
import { AwIndicatorModule } from '@aw/ads-components/aw-indicator';

import {
  ClientEventsModule,
  GOOGLE_TAG_MANAGER_TRACKING_ID,
  INTERNAL_LAMBDA_URL,
} from '@aw/client-events/dist/client-events';
import { ServiceConfig, VideoManagementClientModule } from '@aw/vms-client';
import { I18NEXT_SERVICE, I18NextModule } from 'angular-i18next';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppInitService } from './shared/service/app-init.service';
import { DOCUMENT_TOKEN } from './shared/service/document-token';
import { FeedbackComponent } from './feedback/feedback.component';
import {
  CarepointListServiceUrlToken,
  EnvConfigService,
  EnvUrlNameToken,
} from './shared/service/env-config.service';
import { LOCATION_TOKEN } from './shared/service/location-token';
import { WINDOW_TOKEN } from './shared/service/window-token';
import * as log from 'loglevel';

import { NoAnswerDialogComponent } from './call-conference/_helpers/components/no-answer-dialog/no-answer-dialog.component';
import { NoAnswerDialogOverlayContainer } from './call-conference/_helpers/components/no-answer-dialog/no-answer-dialog-overlay-container';
import { AuthClientConfig, AuthModule } from '@auth0/auth0-angular';
import { ApmErrorHandler, ApmModule, ApmService } from './apm-module';
import { environment } from '@environments/environment';
import { AwProgressBarModule } from '@aw/ads-components/aw-progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { DeviceService } from '@app/shared/service/device.service';
import { ErrorModalComponent } from '@app/shared/components/error-modal/error-modal.component';
import { BootstrapConfig } from '@app/shared/model/bootstrap-config.model';
import { QueryParamKeys } from '@app/shared/constants/query-param-keys';
import { DomainService } from '@app/shared/service/domain-service/domain.service';
import { LOGGER_TOKEN } from '@app/shared/service/logger-token';
import { AwFooterModule } from '@aw/ads-components/aw-footer';
import { SESSION } from '@amwellnow/app-embedded/constants';
import { TimeoutWidgetModule, TimeoutWidgetService } from '@aw/timeout-widget';
import { LogoutComponent } from './auth/logout/logout.component';
import { TranslateCutModule } from './shared/pipes/translate-cut/translate-cut.module';
import { NotFoundModalComponent } from '@app/not-found-modal/not-found-modal.component';
import { HeaderInterceptor } from './header.interceptor';
import { AwFeatureFlags } from '@aw/ads-components/src/interfaces';
import { FEATURE_FLAGS_TOKEN } from '@aw/ads-components/src/services/feature-flags';
import { NotificationsModule } from '@aw/ads-components';

registerLocaleData(localeIw);

export const BOOTSTRAP_CONFIG = new InjectionToken<BootstrapConfig>('bootstrap.config');

/** An injection token for initialization callbacks */
const ConfigurationDependencies = new InjectionToken<(() => () => void)[]>('config.dependencies');
/** A function for configuring the environment service */
const configureEnvService = async (
  envConfigService: EnvConfigService,
  authClientConfig: AuthClientConfig,
  domainService: DomainService,
  location: Location,
) => {
  const appConfig = await envConfigService.init();
  const queryParams = new URLSearchParams(location.search);
  const ref = queryParams.get(QueryParamKeys.ref);
  const referrer = queryParams.get(QueryParamKeys.referrer);
  const tlaConfig = await domainService.getTlaConfig(appConfig).toPromise();
  await envConfigService.loadAdsFeatureFlags(tlaConfig.tenantKey || '');
  if (ref === 'grand-central' && referrer) {
    sessionStorage.setItem(SESSION.REF, JSON.stringify({ ref, referrer }));

    if (tlaConfig && tlaConfig.auth) {
      Object.assign(appConfig.auth, tlaConfig.auth);
    }
  }

  authClientConfig.set({
    ...appConfig.auth,
    redirectUri: `${location.origin}/auth${location.search}`,
    errorPath: '/error',
  });

  return appConfig;
};

/** Resolve configurations resolves promises in the order they're provided */
export function resolveConfigurations(configurations) {
  return () =>
    configurations.reduce((previous, current) => {
      return previous.then(() => current());
    }, Promise.resolve());
}

@NgModule({
  declarations: [
    AppComponent,
    BaseComponent,
    NoAnswerDialogComponent,
    NotFoundModalComponent,
    FeedbackComponent,
    ErrorModalComponent,
    LogoutComponent,
  ],
  imports: [
    ApmModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    OverlayModule,
    I18NextModule.forRoot({}),
    VideoManagementClientModule,
    ClientEventsModule,
    AwButtonModule,
    AwPanelModule,
    AwResponsiveLayoutModule,
    AwCopyrightModule,
    AwIndicatorModule,
    AwFloatingDialogModule,
    AuthModule.forRoot(),
    AwProgressBarModule,
    AwFooterModule,
    MatDialogModule,
    TranslateCutModule,
    AwProgressBarModule,
    TimeoutWidgetModule,
    NotificationsModule,
  ],
  exports: [RouterModule],
  providers: [
    TimeoutWidgetService,
    {
      provide: ErrorHandler,
      useClass: ApmErrorHandler,
    },
    I18N_PROVIDERS,
    { provide: LOCATION_TOKEN, useValue: window.location },
    { provide: DOCUMENT_TOKEN, useValue: window.document },
    { provide: WINDOW_TOKEN, useValue: window },
    { provide: LOGGER_TOKEN, useValue: log },
    {
      provide: CarepointListServiceUrlToken,
      useFactory: (envConfigService: EnvConfigService) => {
        return envConfigService.getSettings().carepointDeviceServiceUrl;
      },
      deps: [EnvConfigService],
    },
    {
      provide: EnvUrlNameToken,
      useFactory: (envConfigService: EnvConfigService) => envConfigService.getSettings().envUrlName,
      deps: [EnvConfigService],
    },
    {
      provide: GOOGLE_TAG_MANAGER_TRACKING_ID,
      useFactory: (envConfigService: EnvConfigService) =>
        envConfigService.init().then(() => {
          return envConfigService.getSettings().clientEventsGtmId;
        }),
      deps: [EnvConfigService],
    },
    {
      provide: INTERNAL_LAMBDA_URL,
      useFactory: (envConfigService: EnvConfigService) =>
        envConfigService.init().then(() => {
          return envConfigService.getSettings().clientEventsLambdaUrl;
        }),
      deps: [EnvConfigService],
    },
    AppInitService,
    DeviceService,
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
    Title,
    EnvConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: resolveConfigurations,
      multi: true,
      deps: [ConfigurationDependencies],
    },
    {
      provide: ConfigurationDependencies,
      /**
       * Configure the application to configure the environment service before anything else.
       */
      useFactory: (
        configService,
        I18NextService,
        injector,
        authClientConfig,
        domainService,
        location,
      ) => [
        () =>
          configureEnvService(configService, authClientConfig, domainService, location).then(() => {
            return configureI18Next(injector.get(AppInitService), I18NextService)();
          }),
      ],
      deps: [
        EnvConfigService,
        I18NEXT_SERVICE,
        Injector,
        AuthClientConfig,
        DomainService,
        LOCATION_TOKEN,
      ],
    },
    {
      // TODO: Move this to its own module
      provide: ServiceConfig,
      useFactory: (envConfigService: EnvConfigService) => {
        // Please make sure the order stays the same in this array.
        return {
          endpoints: [
            {
              name: 'createOrUpdateParticipant',
              url: `${envConfigService.getSettings().vmsServiceUrl}`,
              method: 'POST',
            },
            {
              name: 'getLiteToken',
              url: `${envConfigService.getSettings().authServiceUrl}/jwt/amwell/lite`,
              method: 'POST',
            },
            {
              name: 'createOrUpdateGhostParticipant',
              url: `${envConfigService.getSettings().vmsServiceUrl}`,
              method: 'POST',
            },
          ],
        };
      },
      deps: [EnvConfigService],
    },
    {
      provide: OverlayContainer,
      useClass: NoAnswerDialogOverlayContainer,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true,
    },
    {
      provide: FEATURE_FLAGS_TOKEN,
      useFactory: (envConfigService: EnvConfigService): AwFeatureFlags =>
        envConfigService.adsFeatureFlags,
      deps: [EnvConfigService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(public service: ApmService, @Inject(BOOTSTRAP_CONFIG) config: BootstrapConfig) {
    service.init({
      /** this name identifies the service in Elastic, it can be anything */
      serviceName: environment.name,
      /** this url identifies where to send all of the event payloads */
      serverUrl: config.elasticAPMURL,
      environment: config.environment,
      serviceVersion: environment.version,
      breakdownMetrics: true,
      disableInstrumentations: ['error', 'history', 'eventtarget'],
      distributedTracingOrigins: [
        /https?:\/\/(.*\.)?aehr\.dev.*/,
        /https?:\/\/(.*\.)?amwell\.systems.*/,
      ],
    });
  }
}
